
import React from 'react';
import Text from 'blocks/Text';
import Video from 'blocks/Video';
import Default from 'blocks/Default';

const availableBlocks = {
  'Text': Text,
'Video': Video,
};

const Blocks = ({block, rowIndex}) => {
    const blockName = block.__typename.replace(/.*_([a-z]*)$/gi, '$1');

    return availableBlocks[blockName]
        ?  React.createElement(availableBlocks[blockName], {Blocks, rowIndex, ...block})
        : <Default blockType={blockName}/>;
};

export default Blocks;
